<template>
  <div class="bg-gray-200 w-full min-h-screen py-4 md:py-12">
    <div class="container mx-auto mb-4 md:mb-8 flex justify-center">
      <a :href="mainUrl">
        <div class="flex items-center h-16">
          <svg
            viewBox="0 0 386 386"
            class="h-full fill-current text-primary"
          >
            <path d="M0,206.3v174.1c0,3.1,2.5,5.5,5.5,5.5h174.1c4.9,0,7.4-6,3.9-9.4L9.4,202.4C6,199,0,201.4,0,206.3z" />
            <path d="M179.7,0H5.5C2.5,0,0,2.5,0,5.5v174.1c0,4.9,6,7.4,9.4,3.9L183.6,9.4C187,6,184.6,0,179.7,0z" />
            <path d="M202.4,376.6l179.7-179.7c2.1-2.1,2.1-5.6,0-7.8L202.4,9.4c-3.5-3.5-9.4-1-9.4,3.9v359.4 C193,377.6,198.9,380.1,202.4,376.6z" />
            <circle
              cx="113"
              cy="193"
              r="72.2"
            />
          </svg>
          <h1 class="ml-4 leading-none text-4xl font-semibold hidden sm:block lg:hidden xl:block">KingOfDog</h1>
        </div>
      </a>
    </div>

    <router-view />
  </div>
</template>

<script>
import config from "@/utils/config";

export default {
  data() {
    return {
      mainUrl: config.app.url
    };
  },
  metaInfo: {
    titleTemplate: '%s - KingOfDog.de Account',
  }
};
</script>