<template>
  <form
    :method="form.config.method"
    :action="form.config.action + '&return_to=http://127.0.0.1:8081/'"
  >
    <div
      v-if="form.config.messages"
      class="w-full flex flex-col mb-4"
    >
      <div
        v-for="message in form.config.messages"
        :key="message.id"
        class="my-1 p-2 rounded-lg bg-red-500 text-white"
      >{{message.text}}</div>
    </div>

    <input
      v-if="getField('csrf_token')"
      name="csrf_token"
      type="hidden"
      :value="getField('csrf_token').value"
      required
    />

    <!-- Identifier input -->
    <v-input
      v-if="getField('identifier')"
      v-model="getField('identifier').value"
      name="identifier"
      type="text"
      required
      :label="$t('auth.identifier.label')"
      :errors="getErrors('identifier')"
    />

    <!-- Email input -->
    <v-input
      v-if="getField('traits.email')"
      v-model="getField('traits.email').value"
      name="traits.email"
      type="email"
      required
      :label="$t('auth.email.label')"
    />

    <!-- Username input -->
    <v-input
      v-if="getField('traits.username')"
      v-model="getField('traits.username').value"
      name="traits.username"
      type="text"
      required
      :label="$t('auth.username.label')"
    />

    <!-- Password input -->
    <v-input
      v-if="getField('password')"
      v-model="getField('password').value"
      name="password"
      type="password"
      required
      :label="$t('auth.password.label')"
      :errors="getErrors('password')"
    />
    <router-link
      v-if="type === 'login'"
      to="/recovery"
      tag="a"
      class="block mt-1 text-sm text-gray-600 text-right hover:text-primary focus:text-primary-dark"
    >Passwort vergessen?</router-link>

    <!-- Password repeat -->
    <v-input
      v-if="getField('password') && (type == 'register' || type == 'settings')"
      v-model="passwordRepeat"
      name="password-repeat"
      type="password"
      required
      :label="$t('auth.password-repeat.label')"
    />

    <div class="flex flex-row-reverse justify-between mt-8">
      <slot name="footer" />
    </div>
  </form>
</template>

<script>
import VInput from "./Input";

export default {
  components: { VInput },
  props: ["form", "type"],
  data() {
    return {
      passwordRepeat: "",
    };
  },

  methods: {
    getField(name) {
      return this.form.config.fields.find((field) => field.name === name);
    },
    getErrors(name) {
      if (!this.getField(name).messages) {
        return [];
      }
      return this.getField(name).messages.map((message) => message.text);
    },
  },
};
</script>