<template>
  <div class="container mx-auto py-4" :class="'max-w-' + width">
    <div class="px-8 py-4 bg-white shadow-lg rounded">
      <h1 class="mb-4 font-header font-black text-4xl text-gray-900">{{title}}</h1>

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    width: {
      type: String,
      default: "sm"
    }
  }
};
</script>