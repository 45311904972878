<template>
  <form :method="form.config.method" :action="form.config.action">
    <input
      v-for="field in form.config.fields"
      :key="field.name"
      :name="field.name"
      :type="field.type"
      :value="field.value"
      :required="field.required"
      class="border border-gray-300 rounded-lg mb-2 px-2 py-1 text-gray-800 transition-shadow duration-200 hover:border-gray-500 focus:border-primary focus:text-black focus:shadow-lg focus:outline-none"
    />
  </form>
</template>

<script>
export default {
  props: ["form"]
};
</script>