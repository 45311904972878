<template>
  <div class="w-full flex flex-col mb-4">
    <transition-group name="messages">
      <message
        :message="message"
        v-for="message of messages"
        :key="message.id"
      />
    </transition-group>
  </div>
</template>

<script>
import Message from "./Message";

export default {
  components: { Message },
  props: ["messages"]
};
</script>

<style>
.messages-enter-active,
.messages-leave-active {
  @apply transition-all;
  @apply duration-300;
}

.messages-enter,
.messages-leave-to {
  @apply py-0;
  @apply overflow-hidden;
  max-height: 0;
}

.messages-enter-to,
.messages-leave {
  max-height: 50px;
}
</style>