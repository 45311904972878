<template>
  <div class="block">
    <div class="block relative mt-8">
      <div
        :class="{ 'border-primary shadow-lg': isFocussed, 'hover:border-gray-600': !isFocussed }"
        class="flex items-center w-full rounded text-gray-800 border border-solid border-gray-400 transition-shadow duration-200"
      >
        <input
          :id="'input' + name"
          ref="input"
          :type="actualType"
          :name="name"
          :required="required"
          @focus="isFocussed = true"
          @blur="isFocussed = false"
          @change="isEmpty = $event.target.value.length === 0"
          v-bind:value="value"
          v-on:input="$emit('input', $event.target.value)"
          class="flex-1 h-full px-4 py-2 rounded focus:outline-none"
        />

        <button
          v-if="type === 'password' && allowToggleVisible"
          type="button"
          class="w-8 h-8 flex items-center justify-center rounded-full mx-2 hover:bg-gray-500 hover:bg-opacity-25 focus:bg-gray-500 focus:bg-opacity-50"
          @click="actualType = actualType === type ? 'text' : type"
        >
          toggle
        </button>
      </div>
      <label
        for="input"
        class="absolute w-full h-full inset-0 px-4 py-2 text-gray-600 pointer-events-none transition-all duration-200"
        :class="{ 'placeholder-as-label': isFocussed || !isEmpty, 'text-primary': isFocussed }"
      >{{label}}</label>
    </div>
    <div v-if="errors">
      <div
        v-for="error in errors"
        :key="error"
        class="mt-1 text-sm text-red-600"
      >{{error}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    type: {
      type: String,
      default: "text"
    },
    name: String,
    required: {
      type: Boolean,
      default: false
    },
    errors: Array,
    allowToggleVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFocussed: false,
      isEmpty: true,
      actualType: this.type
    };
  },
  created() {
    this.updated();
  },
  methods: {
    updated() {
      this.isEmpty = this.value.length == 0;
    },
    focus() {
      this.isFocussed = true;
      this.$refs.input.focus();
    }
  },
  watch: {
    value() {
      this.updated();
    },
    type() {
      this.actualType = this.type;
    }
  }
};
</script>

<style scoped>
.placeholder-as-label {
  @apply text-sm;
  transform: translate(-3px, -80%);
}
</style>