<template>
  <card title="Login">
    <form-oidc
      v-if="forms.oidc"
      :form="forms.oidc"
    />
    <form-credentials
      v-if="forms.password"
      :form="forms.password"
      type="login"
    >
      <template slot="footer">
        <button
          type="submit"
          class="px-4 py-2 rounded-lg bg-primary text-white font-medium text-sm hover:bg-primary-light focus:bg-primary-dark"
        >{{$t('auth.login.submit')}}</button>

        <router-link
          :to="{ name: 'register', query: {r: this.returnTo} }"
          tag="a"
          class="px-4 py-2 rounded-lg text-primary font-medium text-sm hover:bg-gray-200 focus:bg-gray-400"
        >{{$t('auth.login.no_account') }}</router-link>
      </template>
    </form-credentials>
  </card>
</template>

<script>
import axios from "axios";

import config from "@/utils/config";
import { parseQueryParams } from "@/utils/url";

import Card from "../components/Card";
import FormOidc from "../components/FormOIDC";
import FormCredentials from "../components/FormCredentials";

export default {
  components: { Card, FormOidc, FormCredentials },
  data() {
    return {
      request: "",
      returnTo: "",
      forms: {}
    };
  },
  beforeRouteEnter() {
    location.href =
      config.oauth.base +
      `/authorize?client_id=${
        config.oauth.clientId
      }&response_type=code&redirect_uri=${encodeURI(config.oauth.callback)}`;
    return;

    /* if (!to.query.request) {
      let params = "";
      if (to.query.r) {
        params += `?return_to=${to.query.r}`;
      }

      location.href =
        config.auth.url + "/self-service/browser/flows/login" + params;
      return;
    }
    next(); */
  },
  mounted() {
    this.request = this.$route.query.request;
    this.returnTo = this.$route.query.r;
    axios({
      url: config.auth.url + "/self-service/browser/flows/requests/login",
      withCredentials: true,
      params: {
        request: this.request
      }
    }).then(
      response => {
        this.forms = response.data.methods;

        const url = response.data.request_url;
        const params = parseQueryParams(url);
        if (params.return_to) {
          this.returnTo = params.return_to;
          this.$router.replace({
            query: { request: this.request, r: this.returnTo }
          });
        }
      },
      error => {
        if (
          error.response &&
          (error.response.status === 410 || error.response.status === 403)
        ) {
          let params = "";
          if (this.$route.query.r) {
            params += `?return_to=${this.returnTo}`;
          }

          location.href =
            config.auth.url + "/self-service/browser/flows/login" + params;
        }
      }
    );
  },
  metaInfo() {
    return {
      title: this.$t("auth.login.title")
    };
  }
};
</script>