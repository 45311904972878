import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import i18n from './i18n'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')