<template>
  <card :title="$t('verify.title')">
    <message-list :messages="messages" />

    <form
      v-if="form.fields"
      :method="form.method"
      :action="form.action"
    >
      <input
        v-if="getField('csrf_token')"
        name="csrf_token"
        type="hidden"
        :value="getField('csrf_token').value"
        required
      />

      <!-- Email input -->
      <v-input
        v-model="getField('to_verify').value"
        name="to_verify"
        type="email"
        required
        :label="$t('auth.email.label')"
        :errors="getErrors('to_verify')"
      />

      <div class="flex flex-row-reverse justify-between mt-8">
        <button
          type="submit"
          class="px-4 py-2 rounded-lg bg-primary text-white font-medium text-sm hover:bg-primary-light focus:bg-primary-dark"
        >{{$t('verify.submit')}}</button>
      </div>
    </form>
  </card>
</template>

<script>
import config from '@/utils/config';

import Axios from 'axios';

import Card from '@/components/Card';
import MessageList from '@/components/MessageList';
import VInput from '@/components/Input';

export default {
  components: { Card, MessageList, VInput },
  data() {
    return {
      request: '',
      messages: [],
      form: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.request) {
      location.href = config.auth.url + '/self-service/browser/flows/verification/email';
      return;
    }
    next();
  },
  mounted() {
    this.request = this.$route.query.request;
    Axios({
      url: config.auth.url + '/self-service/browser/flows/requests/verification',
      withCredentials: true,
      params: {
        request: this.request,
      }
    }).then((response) => {
      this.messages = response.data.messages;
      this.form = response.data.form;
    }, error => {
      console.log(error.response);
    });
  },
  methods: {
    getField(name) {
      return this.form.fields.find((field) => field.name === name);
    },
    getErrors(name) {
      if (!this.getField(name).messages) {
        return [];
      }
      return this.getField(name).messages.map((message) => message.text);
    }
  },
  metaInfo() {
    return {
      title: this.$t('verify.title'),
    }
  }
}
</script>