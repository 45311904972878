<template>
  <div>
    <card
      :title="$t('settings.account.title')"
      width="xl"
      class="mb-8"
    >
      <account-settings
        v-if="profile"
        :value="profile"
        :messages="messages.account"
        @submit="onAccountSettingsUpdated"
      >
        <template slot="footer">
          <button
            type="submit"
            class="px-4 py-2 rounded-lg bg-primary text-white font-medium text-sm hover:bg-primary-light focus:bg-primary-dark"
          >{{$t('settings.save')}}</button>
        </template>
      </account-settings>
    </card>

    <card
      :title="$t('settings.profile.title')"
      width="xl"
      class="mb-8"
    >
      <div>
        <profile-settings
          v-if="profile"
          :profile="profile"
          :messages="messages.profile"
          @update="onProfileSettingsUpdated"
        ></profile-settings>
      </div>
    </card>

    <card
      :title="$t('settings.security.title')"
      width="xl"
      class="mb-8"
    >
      <div>
        <password-settings
          :messages="messages.password"
          @submit="onPasswordSettingsUpdated"
          ref="passwordSettings"
        >
          <template slot="footer">
            <button
              type="submit"
              class="px-4 py-2 rounded-lg bg-primary text-white font-medium text-sm hover:bg-primary-light focus:bg-primary-dark"
            >{{$t('settings.save')}}</button>
          </template>
        </password-settings>
      </div>
    </card>

    <!-- <card
      :title="$t('settings.openid.title')"
      width="xl"
      class="mb-8"
    >
      <div></div>
    </card> -->
  </div>
</template>

<script>
import axios from "axios";

import config from "@/utils/config";

import Card from "../components/Card";
import AccountSettings from "../components/Settings/AccountSettings";
import ProfileSettings from "../components/Settings/ProfileSettings";
import PasswordSettings from "../components/Settings/PasswordSettings";

export default {
  components: { Card, AccountSettings, ProfileSettings, PasswordSettings },
  data() {
    return {
      profile: null,
      messages: {
        account: null,
        profile: null,
        password: null
      }
    };
  },
  async created() {
    const response = await axios
      .get(config.api.users + "/profiles/own", {
        withCredentials: true
      })
      .catch(error => {
        if (error.response && error.response.status == 401) {
          // TODO make robust
          location.href =
            "http://127.0.0.1:3000/oauth2/login?r=" + location.href;
        }
        return { data: null };
      });
    this.profile = response.data;
  },
  methods: {
    showMessage(where, type, content) {
      if (!this.messages[where]) {
        this.messages[where] = [];
      }
      const message = {
        id: Math.round(Math.random() * 10000),
        type,
        text: content
      };
      this.messages[where].push(message);

      setTimeout(() => {
        const index = this.messages[where].indexOf(message);
        if (index !== -1) {
          this.messages[where].splice(index, 1);
        }
      }, 10000);
    },
    onAccountSettingsUpdated(settings) {
      this.messages.account = [];
      axios
        .patch(config.api.users + "/accounts/own", settings, {
          withCredentials: true
        })
        .then(
          response => {
            this.profile = response.data;
            this.showMessage(
              "account",
              "success",
              this.$t("settings.account.success")
            );
          },
          () => {
            this.profile = Object.assign({ updated: Date.now() }, this.profile);
            this.showMessage(
              "account",
              "error",
              this.$t("settings.account.errors.general")
            );
          }
        );
    },
    onProfileSettingsUpdated(settings) {
      this.messages.profile = [];
      axios
        .patch(config.api.users + "/profiles/" + this.profile.id, settings, {
          withCredentials: true
        })
        .then(
          response => {
            this.profile = response.data;
            this.showMessage(
              "profile",
              "success",
              this.$t("settings.profile.success")
            );
          },
          () => {
            this.profile = Object.assign({ updated: Date.now() }, this.profile);
            this.showMessage(
              "profile",
              "error",
              this.$t("settings.profile.errors.general")
            );
          }
        );
    },
    onPasswordSettingsUpdated(settings) {
      axios
        .patch(config.api.users + "/accounts/own/password", settings, {
          withCredentials: true
        })
        .then(
          () => {
            this.showMessage(
              "password",
              "success",
              this.$t("settings.password.success")
            );
            this.$refs.PasswordSettings.reset();
          },
          () => {
            this.showMessage(
              "password",
              "error",
              this.$t("settings.password.errors.general")
            );
            this.$refs.PasswordSettings.reset();
          }
        );
    }
  },
  metaInfo() {
    return {
      title: this.$t("settings.title")
    };
  }
};
</script>