<template>
  <card title="Recovery">
    <div class="w-full flex flex-col mb-4">
      <div
        v-for="message in messages"
        :key="message.id"
        :class="{ 'bg-green-500 text-white': message.type === 'info', 'bg-red-500 text-white': message.type === 'error' }"
        class="my-1 p-2 rounded-lg"
      >{{message.text}}</div>
    </div>

    <form
      v-if="form.config"
      :method="form.config.method"
      :action="form.config.action"
    >
      <input
        v-if="getField('csrf_token')"
        name="csrf_token"
        type="hidden"
        :value="getField('csrf_token').value"
        required
      />

      <!-- Email input -->
      <v-input
        v-model="getField('email').value"
        name="email"
        type="email"
        required
        :label="$t('auth.email.label')"
        :errors="getErrors('email')"
      />

      <div class="flex flex-row-reverse justify-between mt-8">
        <button
          type="submit"
          class="px-4 py-2 rounded-lg bg-primary text-white font-medium text-sm hover:bg-primary-light focus:bg-primary-dark"
        >{{$t('recovery.submit')}}</button>
      </div>
    </form>
  </card>
</template>

<script>
import Axios from 'axios';

import config from '@/utils/config';

import Card from '../components/Card';
import VInput from '../components/Input';

export default {
  components: { Card, VInput },
  data() {
    return {
      request: '',
      form: {},
      messages: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.request) {
      location.href = config.auth.url + '/self-service/browser/flows/recovery';
      return
    }
    next();
  },
  mounted() {
    this.request = this.$route.query.request;
    this.forms = Axios({
      url: config.auth.url + '/self-service/browser/flows/requests/recovery',
      withCredentials: true,
      params: {
        request: this.request,
      }
    }).then(response => {
      this.form = response.data.methods.link;
      this.messages = response.data.messages;
    }, error => {
      if (error.response && error.response.status === 410) {
        location.href = config.auth.url + '/self-service/browser/flows/login';
      }
    });
  },
  methods: {
    getField(name) {
      return this.form.config.fields.find((field) => field.name === name);
    },
    getErrors(name) {
      if (!this.getField(name).messages) {
        return [];
      }
      return this.getField(name).messages.map((message) => message.text);
    }
  },
  metaInfo() {
    return {
      title: this.$t('recovery.title'),
    };
  }
}
</script>