<template>
  <card title="Registrieren">
    <form-oidc
      v-if="forms.oidc"
      :form="forms.oidc"
    />
    <form-credentials
      v-if="forms.password"
      :form="forms.password"
      type="register"
    >
      <template slot="footer">
        <button
          type="submit"
          class="px-3 py-2 rounded-lg bg-primary text-white text-sm font-medium hover:bg-primary-light focus:bg-primary-dark"
        >{{$t('auth.register.submit')}}</button>

        <router-link
          :to="{ name: 'login', query: { r: this.returnTo } }"
          tag="a"
          class="px-3 py-2 rounded-lg text-primary text-sm font-medium hover:bg-gray-200 focus:bg-gray-400"
        >{{$t(`auth.register.existing_account`) }}</router-link>
      </template>
    </form-credentials>
  </card>
</template>

<script>
import axios from "axios";

import config from "@/utils/config";
import { parseQueryParams } from "@/utils/url";

import Card from "../components/Card";
import FormOidc from "../components/FormOIDC";
import FormCredentials from "../components/FormCredentials";

export default {
  components: { Card, FormOidc, FormCredentials },
  data() {
    return {
      request: "",
      forms: {}
    };
  },
  beforeRouteEnter(to, from, next) {
    if (!to.query.request) {
      let params = "";
      if (to.query.r) {
        params += `?return_to=${to.query.r}`;
      }
      location.href =
        config.auth.url + "/self-service/browser/flows/registration" + params;
      return;
    }
    next();
  },
  mounted() {
    this.request = this.$route.query.request;
    this.returnTo = this.$route.query.r;
    this.forms = axios({
      url:
        config.auth.url + "/self-service/browser/flows/requests/registration",
      withCredentials: true,
      params: {
        request: this.request
      }
    }).then(
      response => {
        this.forms = response.data.methods;

        const url = response.data.request_url;
        const params = parseQueryParams(url);
        if (params.return_to) {
          this.returnTo = params.return_to;
          this.$router.replace({
            query: { request: this.request, r: this.returnTo }
          });
        }
      },
      error => {
        if (error.response && error.response.status === 410) {
          let params = "";
          if (this.$route.query.r) {
            params += `?return_to=${this.returnTo}`;
          }

          location.href =
            config.auth.url +
            "/self-service/browser/flows/registration" +
            params;
        }
      }
    );
  },
  metaInfo() {
    return {
      title: this.$t("auth.register.title")
    };
  }
};
</script>

<style>
</style>