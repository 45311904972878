const config = {
    auth: {
        url: process.env.VUE_APP_AUTH_URL || '',
    },
    app: {
        url: process.env.VUE_APP_MAIN_URL || '',
    },
    api: {
        url: process.env.VUE_APP_API_URL || '',
        users: process.env.VUE_APP_USERS_API_URL || '',
    },
    files: {
        url: process.env.VUE_APP_FILES_URL || '',
    },
    oauth: {
        clientId: 'c6243134-c188-4378-8830-f6d83cb3e9ec',
        base: 'https://auth.kingofdog.de/oauth2',
        callback: 'http://127.0.0.1:3000/oauth2/callback',
    }
}

export default config;