<template>
  <card :title="$t('verify.success.title')">
    <div class="flex justify-center">
      <a
        :href="returnUrl"
        class="px-4 py-2 rounded-lg bg-primary text-white font-medium hover:bg-primary-light focus:bg-primary-dark"
      >{{$t('verify.success.cta')}}</a>
    </div>
  </card>
</template>

<script>
import config from '@/utils/config';

import Card from '../components/Card';

export default {
  components: { Card },
  computed: {
    returnUrl() {
      return config.app.url;
    }
  },
  metaInfo() {
    return {
      title: this.$t('verify.success.title'),
    }
  }
}
</script>