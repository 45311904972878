<template>
  <form @submit.prevent="">
    <div
      v-if="messages && messages.length > 0"
      class="w-full flex flex-col mb-4"
    >
      <div
        v-for="message in messages"
        :key="message.id"
        class="my-1 p-2 rounded-lg bg-red-500 text-white"
      >{{message.text}}</div>
    </div>

    <!-- Avatar input -->
    <div class="mx-4 my-8">
      <label class="block font-bold mb-4">{{$t('settings.profile.avatar.label')}}</label>
      <div class="flex items-center">
        <div
          class="w-24 h-24 mr-8 rounded-full bg-white bg-cover bg-center bg-no-repeat"
          :style="{ backgroundImage: `url(${avatarUrl})` }"
        />
        <file-upload
          @upload="avatar = $event.id; $emit('update', { avatar: $event.id })"
          :allowedTypes="['image/png', 'image/jpg']"
          :multiple="false"
        />
      </div>
    </div>

    <div class="flex flex-row-reverse justify-between mt-4">
      <slot name="footer" />
    </div>
  </form>
</template>

<script>
import config from "@/utils/config";

import FileUpload from "../FileUpload";

export default {
  components: { FileUpload },
  props: {
    profile: {
      type: Object,
      required: true
    },
    messages: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      avatar: null
    };
  },
  computed: {
    avatarUrl() {
      if (this.avatar) {
        return config.files.url + "/" + this.avatar;
      }
      return "https://via.placeholder.com/500x500";
    }
  },
  mounted() {
    this.avatar = this.profile?.avatar;
  },
  watch: {
    profile() {
      this.avatar = this.profile.avatar;
    }
  }
};
</script>