<template>
  <form
    v-if="settings"
    @submit.prevent="$emit('submit', settings)"
  >
    <message-list :messages="messages"></message-list>

    <!-- Email input -->
    <v-input
      v-model="settings.email"
      name="email"
      type="email"
      required
      :label="$t('auth.email.label')"
    />

    <!-- Username input -->
    <v-input
      v-model="settings.username"
      name="username"
      type="text"
      required
      :label="$t('auth.username.label')"
    />

    <div class="flex flex-row-reverse justify-between mt-4">
      <slot name="footer" />
    </div>
  </form>
</template>

<script>
import VInput from "../Input";
import MessageList from "../MessageList";

export default {
  components: { VInput, MessageList },
  props: {
    value: {
      type: Object,
      required: true
    },
    messages: {
      type: Array,
      default: null
    }
  },
  mounted() {
    this.settings = Object.assign({}, this.value);
  },
  data() {
    return {
      settings: null
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.settings = Object.assign({}, this.value);
      }
    }
  }
};
</script>