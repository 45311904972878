<template>
  <input
    @change="onFileSelected"
    type="file"
    ref="file"
    :multiple="multiple"
    :accept="allowedTypes"
  />
</template>

<script>
import axios from "axios";

import config from "@/utils/config";

export default {
  props: {
    allowedTypes: {
      default: "*/*"
    },
    multiple: {
      default: false
    }
  },
  methods: {
    onFileSelected() {
      const files = this.$refs.file.files;
      const formData = new FormData();

      if (this.multiple) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
      } else {
        formData.append("files", files[0]);

        axios
          .post(config.files.url, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
            params: {
              scope: "user"
            }
          })
          .then(response => {
            console.log(response);

            const files = response.data.files || [response.data.file];

            files.forEach(result => {
              this.$emit("upload", result);
            });
          })
          .catch(error => console.log(error));
      }
    }
  }
};
</script>

<style>
</style>