<script>
import config from "@/utils/config";

export default {
  beforeRouteEnter() {
    location.href = config.auth.url + "/self-service/browser/flows/logout";
  },
  metaInfo() {
    return {
      title: this.$t('auth.logout.title'),
    }
  }
};
</script>