<template>
  <div
    :class="{ 'bg-green-500 text-white': message.type === 'success', 'bg-red-500 text-white': message.type === 'error' }"
    class="my-1 p-2 rounded-lg"
  >{{message.text}}</div>
</template>

<script>
export default {
  props: ["message"]
};
</script>