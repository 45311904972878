export function parseQueryParams(url) {
    const parts = url.split('?');
    if (parts.length < 2) {
        return {};
    }

    const params = {};

    const query = parts[1];
    query.split('&').forEach(param => {
        const [key, value] = param.split('=')
        params[key] = value;
    })

    return params;
}