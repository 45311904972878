import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Register from '../views/Register.vue'
import Settings from '../views/Settings';
import Recovery from '../views/Recovery';
import Verify from '../views/Verify';
import VerifySuccess from '../views/VerifySuccess';

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/login',
}, {
  path: '/login',
  name: 'login',
  component: Login,
}, {
  path: '/logout',
  name: 'logout',
  component: Logout,
}, {
  path: '/register',
  name: 'register',
  component: Register,
}, {
  path: '/settings',
  component: Settings,
}, {
  path: '/recovery',
  component: Recovery
}, {
  path: '/verify',
  component: Verify,
}, {
  path: '/verify/success',
  component: VerifySuccess,
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router