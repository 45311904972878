<template>
  <form @submit.prevent="onSubmit">
    <message-list :messages="messages"></message-list>

    <!-- Current password input -->
    <v-input
      v-model="currentPassword"
      name="currentPassword"
      type="password"
      required
      :label="$t('auth.current-password.label')"
    />

    <!-- New password input -->
    <v-input
      v-model="newPassword"
      name="newPassword"
      type="password"
      required
      :allow-toggle-visible="true"
      :label="$t('auth.new-password.label')"
    />

    <!-- Repeat new password input -->
    <v-input
      v-model="repeatNewPassword"
      name="currentPassword"
      type="password"
      required
      :allow-toggle-visible="true"
      :label="$t('auth.new-password.repeat.label')"
    />

    <p class="m-4 text-gray-600 italic">{{$t('settings.password.hints.password-length')}}</p>

    <div class="flex flex-row-reverse justify-between mt-4">
      <slot name="footer" />
    </div>
  </form>
</template>

<script>
import VInput from "../Input";
import MessageList from "../MessageList";

export default {
  components: { VInput, MessageList },
  props: {
    messages: {
      type: Array,
      default: null
    }
  },
  mounted() {
    this.settings = Object.assign({}, this.value);
  },
  computed: {
    msgs() {
      return [...this.messages, ...this.localMessages];
    }
  },
  data() {
    return {
      localMessages: [],
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: ""
    };
  },
  methods: {
    showMessage(type, content) {
      const message = {
        id: Math.round(Math.random() * 10000),
        type,
        text: content
      };
      this.localMessages.push(message);

      setTimeout(() => {
        const index = this.localMessages.indexOf(message);
        if (index !== -1) {
          this.localMessages.splice(index, 1);
        }
      }, 10000);
    },
    onSubmit() {
      if (this.newPassword.length < 8) {
        this.showMessage("error", this.$t("settings.password.errors.length"));
        return;
      }

      if (this.newPassword !== this.repeatNewPassword) {
        this.showMessage("error", this.$t("settings.password.errors.confirm"));
        return;
      }

      this.$emit("submit", {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword
      });
    },
    reset() {
      this.currentPassword = "";
      this.newPassword = "";
      this.repeatNewPassword = "";
    }
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.settings = Object.assign({}, this.value);
      }
    }
  }
};
</script>